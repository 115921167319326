import { useSelector } from 'react-redux'

import { IS_DARK_MODE_BANNER_CLOSED_LOCAL_STORAGE_KEY } from 'constants/dark-mode'
import { getLocalStorageItem } from 'libs/utils/localStorage'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import useIsDarkMode from './useIsDarkMode'

const useShouldShowDarkModeAdoption = () => {
  const isDarkModeAdoptionEnabled = useSelector(getIsFeatureSwitchEnabled('web_dark_mode_adoption'))
  const isDarkMode = useIsDarkMode()

  return (
    isDarkModeAdoptionEnabled &&
    !getLocalStorageItem(IS_DARK_MODE_BANNER_CLOSED_LOCAL_STORAGE_KEY) &&
    !isDarkMode
  )
}

export default useShouldShowDarkModeAdoption
