'use client'

import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@vinted/web-ui'

import useRefUrl from 'hooks/useRefUrl'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { SIGNUP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { toUrlQuery } from 'libs/utils/url'

import { actions as authActions } from 'state/auth/slice'
import { getIsAuthPageOpen } from 'state/auth/selectors'

const HeaderLoginButton = () => {
  const dispatch = useDispatch()
  const refUrl = useRefUrl()
  const translate = useTranslate()
  const { track } = useTracking()
  const isAuthPageOpen = useSelector(getIsAuthPageOpen)

  function handleClick(event: MouseEvent) {
    track(clickEvent({ target: ClickableElement.Login }))

    if (isAuthPageOpen) return

    event.preventDefault()
    dispatch(authActions.openAuthModal())
  }

  return (
    <Button
      text={translate('header.actions.login')}
      size={Button.Size.Small}
      url={`${SIGNUP_URL}?${toUrlQuery({ ref_url: refUrl })}`}
      onClick={handleClick}
      testId="header--login-button"
    />
  )
}

export default HeaderLoginButton
