import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { systemTimingEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking/useTracking'

function useAdLoadtimeLogging(trackingCode: string) {
  const isAdsLoadTimeLoggingEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_load_time_logging'),
  )

  const { track } = useTracking()

  const adRequestTimestampRef = useRef(0)

  const onRequest = () => {
    if (!isAdsLoadTimeLoggingEnabled) return

    adRequestTimestampRef.current = Date.now()
  }

  const onLoad = () => {
    if (!adRequestTimestampRef.current || !isAdsLoadTimeLoggingEnabled) return

    const adLoadTime = Date.now() - adRequestTimestampRef.current

    track(
      systemTimingEvent({
        section: 'ad_load',
        duration: adLoadTime,
        completionState: 'succeeded',
        data: trackingCode,
      }),
    )
  }

  return { onRequest, onLoad }
}

export default useAdLoadtimeLogging
