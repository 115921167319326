'use client'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Divider } from '@vinted/web-ui'

import useAbTest from 'hooks/useAbTest'

import LanguageSelector from 'components/LanguageSelector'
import SeparatedList from 'components/SeparatedList'

import { transformCatalogDtoList } from 'data/transformers/catalog'
import { transformUserDto } from 'data/transformers/user'
import * as sessionSelectors from 'state/session/selectors'
import { CatalogDto } from 'types/dtos'

import AccountLinks from './AccountLinks'
import CategoriesSection from './CategoriesSection'
import LoginSection from './LoginSection'
import OtherSection from './OtherSection'

type Props = {
  catalogTree: Array<CatalogDto>
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const SideNavigation = ({ catalogTree, impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  const userDto = useSelector(sessionSelectors.getUser)
  const transformedUser = useMemo(() => (userDto ? transformUserDto(userDto) : null), [userDto])
  const transformedTree = useMemo(() => transformCatalogDtoList(catalogTree), [catalogTree])
  const buyerHoldoutAbTest = useAbTest({ abTestName: 'buyer_domain_holdout_2024q3' })

  useAbTest({
    abTestName: 'buyer_hvf_catalog_navigation',
    shouldTrackExpose: buyerHoldoutAbTest?.variant === 'on',
  })

  return (
    <SeparatedList separator={<Divider />}>
      <LoginSection user={transformedUser} />
      <CategoriesSection categories={transformedTree} />
      <AccountLinks />
      <LanguageSelector />
      <OtherSection
        impressumUrl={impressumUrl}
        isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
      />
    </SeparatedList>
  )
}

export default SideNavigation
