import { useCallback, useContext, useEffect, useRef } from 'react'

import { BrazeContext } from 'libs/common/braze/containers/BrazeProvider'
import { InboxNotificationType } from 'constants/inbox-notification'
import { GenericInboxNotificationModel } from 'types/models'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import useBrazeInboxNotificationCards from 'libs/common/braze/hooks/useBrazeInboxNotificationCards'

import useTracking from './useTracking'

const useInboxNotificationEvents = () => {
  const { track } = useTracking()
  const { logCardClick, logCardImpression, inboxNotificationCardStore } = useContext(BrazeContext)
  const { brazeControlNotificationCards } = useBrazeInboxNotificationCards()

  const seenNotificationIds = useRef<Array<string>>([])
  const delayControlNotificationTracking = useRef(false)

  const trackNotificationClick = (notification: GenericInboxNotificationModel) => () => {
    switch (notification.type) {
      case InboxNotificationType.Vinted:
        track(
          clickEvent({
            target: ClickableElement.Notification,
            targetDetails: JSON.stringify({ notification_id: notification.id }),
          }),
        )
        break
      case InboxNotificationType.Braze: {
        if (!notification.link) return

        logCardClick(notification.id)
        break
      }
      default:
        break
    }
  }

  const logControlNotificationImpressions = useCallback(() => {
    brazeControlNotificationCards?.forEach(card => logCardImpression(card.id))
    delayControlNotificationTracking.current = false
  }, [brazeControlNotificationCards, logCardImpression])

  useEffect(() => {
    if (delayControlNotificationTracking.current) {
      logControlNotificationImpressions()
    }
  }, [logControlNotificationImpressions])

  const trackControlNotificationEnter = () => {
    const areControlNotificationsLoaded = !!brazeControlNotificationCards

    if (areControlNotificationsLoaded) {
      logControlNotificationImpressions()
    } else {
      delayControlNotificationTracking.current = true
    }
  }

  const trackNotificationEnter = (notification: GenericInboxNotificationModel) => () => {
    const isNotificationSeen = seenNotificationIds.current.includes(notification.id)

    if (!isNotificationSeen) seenNotificationIds.current.push(notification.id)

    const targetDetails = { notification_id: notification.id }

    switch (notification.type) {
      case InboxNotificationType.Vinted:
        if (isNotificationSeen) break

        track(
          viewEvent({
            target: ViewableElement.Notification,
            targetDetails: JSON.stringify(targetDetails),
          }),
        )
        break
      case InboxNotificationType.Braze: {
        const viewedBefore = notification.isViewed

        logCardImpression(notification.id).then(isLogged => {
          if (!isLogged || viewedBefore) return

          if (inboxNotificationCardStore.state) {
            inboxNotificationCardStore.state = Array.from(inboxNotificationCardStore.state)
          }
        })
        break
      }
      default:
        break
    }
  }

  return {
    trackNotificationClick,
    trackNotificationEnter,
    trackControlNotificationEnter,
  }
}

export default useInboxNotificationEvents
