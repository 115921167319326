'use client'

import Script from 'next/script'

import useLocation from 'hooks/useLocation'
import { normalizeHost } from 'libs/utils/url'

const Blockthrough = () => {
  const domainName = normalizeHost(useLocation().host)

  return (
    <Script
      crossOrigin="anonymous"
      id="blockthrough-loader"
      src={`https://btloader.com/tag?o=5146663562248192&domain=${domainName}&upapi=true`}
      strategy="lazyOnload"
    />
  )
}

export default Blockthrough
