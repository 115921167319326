import { SearchSuggestionType } from 'constants/search'

import { SearchSuggestionDto } from 'types/dtos'
import { SearchQuerySuggestionModel, SearchSuggestionModel } from 'types/models'

export const transformSearchSuggestion = (
  suggestion: SearchSuggestionDto,
): SearchSuggestionModel => {
  const { id, title, params } = suggestion

  const commonSuggestionFields = {
    id,
    title,
    params,
  }

  if ('filters' in suggestion) {
    return {
      ...commonSuggestionFields,
      subtitle: suggestion.subtitle,
      query: suggestion.filters.search_text,
      filters: {
        query: suggestion.filters.search_text,
        catalogIds: [suggestion.filters.catalog_id],
      },
      type: SearchSuggestionType.Scoped,
    }
  }

  const querySuggestionTypes: Array<SearchQuerySuggestionModel['type']> = [
    SearchSuggestionType.Brand,
    SearchSuggestionType.Catalog,
    SearchSuggestionType.Multiword,
  ]

  return {
    ...commonSuggestionFields,
    query: title,
    typeId: suggestion.suggestion_id.toString(),
    type: querySuggestionTypes[suggestion.suggestion_type],
  }
}

export const transformSearchSuggestions = (
  suggestions: Array<SearchSuggestionDto>,
): Array<SearchSuggestionModel> => suggestions.map(transformSearchSuggestion)
