'use client'

import { useEffect } from 'react'
import { Image, Text } from '@vinted/web-ui'

import Notification, { NotificationCloseType } from 'components/Notification'
import { InAppNotificationMessageModel } from 'libs/common/braze/types/models/in-app-message'

type Props = {
  inAppMessage: InAppNotificationMessageModel
  onClose: (closeType: NotificationCloseType | undefined) => void
  onEnter: () => void
  onLinkClick: () => void
}

const ONE_SECOND_IN_MILISECONDS = 1000

const NotificationInAppMessage = ({ inAppMessage, onClose, onLinkClick, onEnter }: Props) => {
  useEffect(onEnter, [onEnter])

  const buildLink = (children: JSX.Element) => {
    if (!inAppMessage.url) return children

    const target = inAppMessage.shouldOpenLinkInNewTab ? '_target' : undefined

    return (
      <a href={inAppMessage.url} target={target} onClick={onLinkClick}>
        {children}
      </a>
    )
  }

  const renderBody = () => {
    return buildLink(<Text text={inAppMessage.message} html />)
  }

  const renderImage = () => {
    if (!inAppMessage.imageUrl) return undefined

    return buildLink(<Image src={inAppMessage.imageUrl} size={Image.Size.Large} />)
  }

  const miliSecondsToSeconds = (duration: number) => Math.ceil(duration / ONE_SECOND_IN_MILISECONDS)

  return (
    <Notification
      icon={renderImage()}
      body={renderBody()}
      displayDuration={inAppMessage.duration && miliSecondsToSeconds(inAppMessage.duration)}
      forceVisibility={!inAppMessage.duration}
      position={inAppMessage.position}
      onNotificationClose={onClose}
      testId="in-app-message-notification"
    />
  )
}

export default NotificationInAppMessage
