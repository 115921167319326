'use client'

import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import AdManager from 'libs/common/ad-manager'

import { AdPlatform } from 'constants/ads'
import { INBOX_CATALOG_ITEM_IDS } from 'constants/page-ids'

import useAbTest from 'hooks/useAbTest'
import useIsInPage from 'hooks/useIsInPage'
import useUserAgent from 'hooks/useUserAgent'

import Google from './setups/Google'
import Pubstack from './setups/Pubstack'
import Amazon from './setups/Amazon'
import Prebid from './setups/Prebid'
import Yieldbird from './setups/Yieldbird'
import Confiant from './setups/Confiant'
import Rokt from './setups/Rokt'
import Blockthrough from './setups/Blockthrough'

const AdScripts = () => {
  const isYieldBirdScriptEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_yieldbird_script'),
  )
  const isBlockthroughScriptEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_blockthrough_script'),
  )
  const isRoktScriptEnabled = useSelector(getIsFeatureSwitchEnabled('web_ads_rokt_script'))

  const userAgent = useUserAgent()
  const isAdsPlatformWeb = AdManager.getAdsPlatform(userAgent) === AdPlatform.Web
  const isBlockthroughAbTestPage = useIsInPage(INBOX_CATALOG_ITEM_IDS)
  const isBlockthroughAbTestEnabled =
    isBlockthroughScriptEnabled && isAdsPlatformWeb && isBlockthroughAbTestPage
  const adblockMonetisationAbTest = useAbTest({
    abTestName: 'web_adblock_monetisation',
    shouldTrackExpose: isBlockthroughAbTestEnabled,
  })
  const isBlockthroughFeatureEnabled =
    isBlockthroughAbTestEnabled && adblockMonetisationAbTest?.variant !== 'off'

  useEffect(() => {
    window.adPlacements = {}
  }, [])

  return (
    <>
      <Google />
      <Pubstack />
      <Amazon />
      <Prebid />
      {isYieldBirdScriptEnabled && <Yieldbird />}
      {isRoktScriptEnabled && <Rokt />}
      {/*
        TODO: Cover Blockthrough part in unit tests
        When scaling web_adblock_monetisation (AB test)
        And/or web_blockthrough_ads_script (Feature switch)
      */}
      {isBlockthroughFeatureEnabled && <Blockthrough />}
      <Confiant />
    </>
  )
}

export default AdScripts
