'use client'

import { Text, Image } from '@vinted/web-ui'
import classNames from 'classnames'
import { Suspense, useMemo } from 'react'

import { AdPlatform, AdShape } from 'constants/ads'
import { CDN_IMAGES_URL } from 'constants/index'

import useIsDarkMode from 'hooks/useIsDarkMode'
import useTranslate from 'hooks/useTranslate'

type Props = {
  shape: AdShape
  platform: AdPlatform
}

type PlaceholderVariation = {
  shape: AdShape
  placeholders: Record<'desktop' | 'mobile', Array<string>>
}

const placeholderVariations: Array<PlaceholderVariation> = [
  {
    shape: AdShape.Leaderboard,
    placeholders: {
      desktop: [
        '/ads/placeholders/leaderboard/desktop-1',
        '/ads/placeholders/leaderboard/desktop-2',
      ],
      mobile: ['/ads/placeholders/leaderboard/mobile-1', '/ads/placeholders/leaderboard/mobile-2'],
    },
  },
]

const getAdPlaceholderImageSrc = (shape: AdShape, isDarkMode: boolean, isMobile: boolean) => {
  const placeholders = placeholderVariations.find(
    ({ shape: variationShape }) => variationShape === shape,
  )?.placeholders

  if (!placeholders) return null

  const darkModeSuffix = isDarkMode ? '-dark' : ''
  const platformPlaceholders = isMobile ? placeholders.mobile : placeholders.desktop
  const indexBasedOnHour = new Date().getUTCHours() % platformPlaceholders.length

  return `${CDN_IMAGES_URL}${platformPlaceholders[indexBasedOnHour]}${darkModeSuffix}.png`
}

const AdPlaceholder = ({ shape, platform }: Props) => {
  const translate = useTranslate('advertisements')
  const isDarkMode = useIsDarkMode()
  const isMobile = platform === AdPlatform.Mobile

  const adPlaceholderImageSrc = useMemo(
    () => getAdPlaceholderImageSrc(shape, isDarkMode, isMobile),
    [shape, isDarkMode, isMobile],
  )

  if (!adPlaceholderImageSrc) return null

  return (
    <Suspense>
      <div
        className={classNames('ad-placeholder', `ad-placeholder--${shape}`)}
        data-testid="ad-placeholder"
        aria-hidden="true"
        suppressHydrationWarning
      >
        <Image testId="ad-placeholder-image" src={adPlaceholderImageSrc} />
        {!isMobile && (
          <div className="ad-placeholder__text" suppressHydrationWarning>
            <Text type={Text.Type.Subtitle} theme="primary" bold text={translate('placeholder')} />
          </div>
        )}
      </div>
    </Suspense>
  )
}

export default AdPlaceholder
