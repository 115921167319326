'use client'

import { Divider, Cell, Text, Label, Image } from '@vinted/web-ui'

import SeparatedList from 'components/SeparatedList'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { CatalogWithoutParentIdModel } from 'types/models'
import { CatalogAttribute } from 'constants/catalog'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'

type Props = {
  categories: Array<CatalogWithoutParentIdModel>
}

const CategoriesSection = ({ categories }: Props) => {
  const translate = useTranslate('header.side_bar')
  const { track } = useTracking()

  const handleTitleClick =
    ({ id }: CatalogWithoutParentIdModel) =>
    () => {
      const trakingEvent = selectCatalogEvent({
        catalogIds: [id],
        attributeId: CatalogAttribute.Navigation,
      })

      track(trakingEvent)
    }

  const renderCategoriesItem = (item: CatalogWithoutParentIdModel) => (
    <Cell
      key={item.id}
      type={Cell.Type.Navigating}
      prefix={
        <Image src={item.photo?.url} scaling={Image.Scaling.Cover} size={Image.Size.Regular} />
      }
      body={<Text truncate>{item.title}</Text>}
      url={item.customUrl || item.url}
      urlProps={{ title: item.title }}
      onClick={handleTitleClick(item)}
      testId={`sidebar-category-item-${item.id}`}
      aria={{ 'aria-label': item.title }}
    />
  )

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('categories')} />
      <SeparatedList separator={<Divider />}>{categories.map(renderCategoriesItem)}</SeparatedList>
    </>
  )
}

export default CategoriesSection
