'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl'
import { Text, Icon, Button } from '@vinted/web-ui'
import { Bookmark24, BookmarkFilled24 } from '@vinted/monochrome-icons'

import { withTracking, EventTrackerProps } from 'containers/TrackingProvider'
import { toggleSearchSubscriptionEvent } from 'libs/common/event-tracker/events'

import { State as AppState } from 'state/types'
import { toggleCurrentSearchSubscription } from 'state/saved-searches/actions'
import { getCurrentSearch, getIsCatalogSearchButtonLoading } from 'state/saved-searches/selectors'
import { getUserId } from 'state/session/selectors'
import { getCatalogItemErrors } from 'state/items/selectors'
import {
  getGlobalSearchSessionId,
  getIsPopularInCatalog,
  getQuery,
  getSearchSessionId,
} from 'state/catalog-filters/selectors'

import { BreakpointProps, withBreakpoint } from 'components/Breakpoint'
import { Screen } from 'constants/tracking/screens'

const mapStateToProps = (state: AppState) => ({
  currentSearch: getCurrentSearch(state),
  query: getQuery(state),
  errors: getCatalogItemErrors(state),
  isPopularCatalog: getIsPopularInCatalog(state),
  searchSessionId: getSearchSessionId(state),
  globalSearchSessionId: getGlobalSearchSessionId(state),
  userId: getUserId(state),
  isLoading: getIsCatalogSearchButtonLoading(state),
})

const mapActionsToProps = {
  toggle: toggleCurrentSearchSubscription,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type Props = BreakpointProps &
  EventTrackerProps &
  WrappedComponentProps &
  ConnectedProps<typeof connector>

class SearchSubscribeButton extends Component<Props> {
  toggleSubscription = () => {
    const { userId, currentSearch, query, searchSessionId, toggle, track, globalSearchSessionId } =
      this.props

    if (!userId) return

    toggle({ userId })

    track(
      toggleSearchSubscriptionEvent({
        savedRecentSearchSessionId: null,
        listName: null,
        savedRecentSearchListId: null,
        screen: Screen.Catalog,
        isSubscribing: !currentSearch?.subscribed,
        searchSessionId,
        searchTitle: currentSearch?.title,
        searchQuery: currentSearch?.search_text ?? query,
        globalSearchSessionId,
      }),
    )
  }

  render() {
    const { currentSearch, errors, isPopularCatalog, breakpoints, intl, userId, isLoading } =
      this.props

    if (errors || isPopularCatalog || !userId) return null

    const action = currentSearch?.subscribed ? 'delete' : 'save'
    const text = <FormattedMessage id={`saved_searches.actions.${action}`} />

    const a11yLabel = breakpoints.phones
      ? intl.formatMessage({ id: `saved_searches.a11y.${action}` })
      : undefined

    return (
      <Button
        isLoading={isLoading}
        text={
          !breakpoints.phones && (
            <Text type={Text.Type.Subtitle} theme="amplified" as="span">
              {text}
            </Text>
          )
        }
        icon={
          <Icon
            name={currentSearch?.subscribed ? BookmarkFilled24 : Bookmark24}
            color={currentSearch?.subscribed ? Icon.Color.Primary : Icon.Color.GreyscaleLevel1}
          />
        }
        onClick={this.toggleSubscription}
        aria={{
          'aria-label': a11yLabel,
        }}
        testId={`catalog-search-${action}-button`}
        theme="muted"
      />
    )
  }
}

const SearchSubscribeButtonComposed = withTracking(
  injectIntl(withBreakpoint(SearchSubscribeButton)),
)

export { SearchSubscribeButtonComposed as SearchSubscribeButton }

export default connector(SearchSubscribeButtonComposed)
