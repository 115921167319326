'use client'

import { Badge, Button, Icon } from '@vinted/web-ui'
import { Envelope24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import { INBOX_URL } from 'constants/routes/inbox'
import useBrazeInboxMessageCards from 'libs/common/braze/hooks/useBrazeInboxMessageCards'

const MAX_BADGE_COUNT = 99

type Props = {
  unreadConversationCount?: number
}

const HeaderConversations = ({ unreadConversationCount = 0 }: Props) => {
  const translate = useTranslate('header.a11y.conversations')
  const { inboxMessageCards } = useBrazeInboxMessageCards()

  const brazeConversationCount = inboxMessageCards?.filter(card => !card.viewed).length ?? 0
  const mergedConversationCount = unreadConversationCount + brazeConversationCount
  const badgeConversationCount = inboxMessageCards
    ? Math.min(mergedConversationCount, MAX_BADGE_COUNT)
    : 0
  const badgeSuffix = mergedConversationCount > MAX_BADGE_COUNT ? '+' : ''

  return (
    <>
      <Button
        testId="header-conversations-button"
        styling={Button.Styling.Flat}
        aria={{
          'aria-label': mergedConversationCount
            ? translate('new_conversations', { count: mergedConversationCount })
            : translate('conversations'),
        }}
        iconName={Envelope24}
        icon={<Icon name={Envelope24} color={Icon.Color.GreyscaleLevel4} />}
        url={INBOX_URL}
      />

      {!!badgeConversationCount && (
        <div
          className="user-actions__notification u-cursor-pointer"
          data-testid="conversations-badge"
        >
          <Badge
            content={<span aria-hidden="true">{`${badgeConversationCount}${badgeSuffix}`}</span>}
            theme="warning"
          />
        </div>
      )}
    </>
  )
}

export default HeaderConversations
