'use client'

import { Animation, Button, Loader, Spacer, Text, Divider } from '@vinted/web-ui'

import { NOTIFICATIONS_URL } from 'constants/routes'
import useAsset from 'hooks/useAsset'
import useInboxNotificationEvents from 'hooks/useInboxNotificationEvents'
import useTranslate from 'hooks/useTranslate'
import ScrollableArea from 'components/ScrollableArea'
import SeparatedList from 'components/SeparatedList'
import { GenericInboxNotificationModel } from 'types/models'
import InboxNotificationItem from 'components/InboxNotificationItem'

type Props = {
  isLoading: boolean
  notifications: Array<GenericInboxNotificationModel>
}

const HeaderNotificationDropdown = ({ notifications, isLoading }: Props) => {
  const translate = useTranslate()
  const asset = useAsset('/assets/animations')

  const { trackNotificationClick, trackNotificationEnter } = useInboxNotificationEvents()

  function renderNotification(notification: GenericInboxNotificationModel) {
    return (
      <InboxNotificationItem
        notification={notification}
        key={notification.id}
        onClick={trackNotificationClick(notification)}
        onEnter={trackNotificationEnter(notification)}
        defaultHighlighted={!notification.isViewed}
      />
    )
  }

  if (isLoading) {
    return (
      <div className="u-ui-margin-x-large u-flexbox u-justify-content-center" data-testid="loader">
        <Loader size={Loader.Size.Large} />
      </div>
    )
  }

  if (!notifications.length) {
    return (
      <div className="u-ui-margin-vertical-x-large u-ui-margin-horizontal-x2-large u-flexbox u-flex-direction-column u-align-items-center">
        <Animation
          animationUrl={asset('notifications-empty-state.json')}
          size={Animation.Size.Large}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('header.notifications.empty_state.title')}
          alignment={Text.Alignment.Center}
          type={Text.Type.Title}
        />
        <Spacer />
        <Text
          text={translate('header.notifications.empty_state.body')}
          alignment={Text.Alignment.Center}
          type={Text.Type.Body}
          html
        />
      </div>
    )
  }

  return (
    <div>
      <ScrollableArea>
        <div className="header-notification-dropdown__container">
          <SeparatedList separator={<Divider />}>
            {notifications.map(renderNotification)}
          </SeparatedList>
        </div>
      </ScrollableArea>

      <div className="header-notification-dropdown__button-container">
        <Button
          url={NOTIFICATIONS_URL}
          text={translate('header.notifications.see_all')}
          size={Button.Size.Medium}
        />
      </div>
    </div>
  )
}

export default HeaderNotificationDropdown
