'use client'

import { useSelector } from 'react-redux'
import { Divider, Label } from '@vinted/web-ui'

import SeparatedList from 'components/SeparatedList'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useCompanyWeb from 'hooks/useCompanyWeb'
import {
  ADVERTISING_URL,
  JOBS_URL,
  JOBS_URL_NEW,
  MAIN_NAVIGATION_ABOUT_TAB_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import OtherLink from '../OtherLink'

const Company = () => {
  const translate = useTranslate('header.main_navigation.about.company')
  const { track } = useTracking()
  const {
    isPressMenuEnabled,
    isSustainabilityMenuEnabled,
    newsroomUrl,
    sustainabilityLandingPageUrl,
  } = useCompanyWeb()

  const isJobsPageEnabled = useSelector(getIsFeatureSwitchEnabled('jobs_page'))
  const isNewJobsUrlEnabled = useSelector(getIsFeatureSwitchEnabled('new_jobs_career_portal'))
  const jobsUrl = isNewJobsUrlEnabled ? JOBS_URL_NEW : JOBS_URL

  const handleSustainabilityClick = () => {
    track(
      clickEvent({
        target: ClickableElement.SustainabilityPageLink,
        targetDetails: 'navigation_web',
      }),
    )
  }

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('title')} />
      <SeparatedList separator={<Divider />}>
        <OtherLink title={translate('items.about')} url={MAIN_NAVIGATION_ABOUT_TAB_URL} />
        {isJobsPageEnabled && <OtherLink title={translate('items.jobs')} url={jobsUrl} />}
        {isSustainabilityMenuEnabled && (
          <OtherLink
            title={translate('items.sustainability')}
            url={sustainabilityLandingPageUrl}
            onClick={handleSustainabilityClick}
          />
        )}
        {isPressMenuEnabled && <OtherLink title={translate('items.press')} url={newsroomUrl} />}
        <OtherLink title={translate('items.advertising')} url={ADVERTISING_URL} />
      </SeparatedList>
    </>
  )
}

export default Company
