import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UiState } from 'constants/ui'
import { getUserStats } from 'data/api'
import { getUserId } from 'state/session/selectors'
import * as selectors from 'state/user-stats/selectors'
import { actions } from 'state/user-stats/slice'

const useUserStats = () => {
  const dispatch = useDispatch()
  const userStats = useSelector(selectors.getUserStats)
  const requestState = useSelector(selectors.getRequestState)
  const userId = useSelector(getUserId)

  const handleApiResponse = useCallback(
    (response: Awaited<ReturnType<typeof getUserStats>>) => {
      if ('errors' in response) {
        dispatch(actions.getUserStatsFailure())

        return
      }

      dispatch(actions.getUserStatsSuccess({ stats: response.stats }))
    },
    [dispatch],
  )

  const fetchUserStats = useCallback(() => {
    if (!userId) return

    dispatch(actions.getUserStatsRequest())

    getUserStats(userId).then(handleApiResponse)
  }, [dispatch, userId, handleApiResponse])

  useEffect(() => {
    if (userStats) return
    if (requestState !== UiState.Idle) return

    fetchUserStats()
  }, [fetchUserStats, requestState, userStats])

  return { userStats, fetchUserStats }
}

export default useUserStats
