'use client'

import Script from 'next/script'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CDN_ADS_URL } from 'constants/index'

import { getIsCookieConsentVersion } from 'state/consent/selectors'

import { initPrebidSetup, setPrebidConsentManagement } from './utils'

const Prebid = () => {
  const isCookieConsentVersion = useSelector(getIsCookieConsentVersion)

  useEffect(() => {
    initPrebidSetup()
  }, [])

  useEffect(() => {
    setPrebidConsentManagement(isCookieConsentVersion)
  }, [isCookieConsentVersion])

  return (
    <Script
      id="prebid-script"
      data-testid="prebid-script"
      src={`${CDN_ADS_URL}/prebid.js`}
      strategy="lazyOnload"
    />
  )
}

export default Prebid
