import { PageId } from 'types/page-id'

export const INBOX_CATALOG_ITEM_IDS: Array<PageId> = [
  'inbox',
  'inbox-message',
  'catalog',
  'catalog-by-id',
  'brand',
  'item',
]
