// TODO: Add unit tests

type YieldBirdOptimizationScript = {
  targetingValue: (targetingValueName: string) => string
  dool: boolean
}

export function yieldbirdOptimization() {
  const { targetingValue, dool }: YieldBirdOptimizationScript = {
    targetingValue(name: string) {
      switch (name) {
        case 'bm':
          return Math.random() >= 0.1 ? '1' : '0'
        case 'ab':
          return dool ? 'b' : `a${Math.floor(Math.random() * 10)}`
        case 'dc':
          return dool ? 'd' : `c${Math.floor(Math.random() * 20)}`
        case 'mx':
          return dool ? `m${Math.floor(Math.random() * 180)}` : 'x'
        case 'tt':
          return `tt${Math.floor(Math.random() * 10)}`
        default:
          return ''
      }
    },
    dool: Math.random() >= 0.1,
  }

  const date = new Date()
  const minute = date.getUTCMinutes()
  let hour = date.getUTCHours() - 8
  let day = date.getUTCDay()

  if (hour < 0) {
    hour += 24
    day -= 1
  }

  if (day < 0) {
    day += 7
  }

  window.googletag.cmd.push(() => {
    window.googletag.pubads().setTargeting('yb_bm', targetingValue('bm'))
    window.googletag.pubads().setTargeting('yb_ab', targetingValue('ab'))
    window.googletag.pubads().setTargeting('yb_dc', targetingValue('dc'))
    window.googletag.pubads().setTargeting('yb_mx', targetingValue('mx'))
    window.googletag.pubads().setTargeting('yb_tt', targetingValue('tt'))
    window.googletag.pubads().setTargeting('yb_ff', `${Math.round(Math.random())}`)
    window.googletag.pubads().setTargeting('yb_tm', minute.toString())
    window.googletag.pubads().setTargeting('yb_th', hour.toString())
    window.googletag.pubads().setTargeting('yb_wd', day.toString())
  })
}

export function initGoogletag() {
  window.googletag = window.googletag || { cmd: [] }
}

export function seNonPersonalizedAds(nonPersonalizedAds: boolean) {
  window.googletag.cmd.push(() => {
    window.googletag.pubads().setPrivacySettings({ nonPersonalizedAds })
  })
}

export function setAdsSegments(adsSegments: Record<string, string>) {
  if (!Object.keys(adsSegments).length) return

  window.googletag.cmd.push(() => {
    Object.entries(adsSegments).forEach(([key, value]) =>
      window.googletag.pubads().setTargeting(key, value),
    )
  })
}

export function setupGoogletagServices(isYieldbirdPriceGeniusEnabled: boolean) {
  window.googletag.cmd.push(() => {
    window.googletag.pubads().enableSingleRequest()
    window.googletag.pubads().disableInitialLoad()
    window.googletag.pubads().collapseEmptyDivs()

    if (isYieldbirdPriceGeniusEnabled) {
      yieldbirdOptimization()
    }

    window.googletag.enableServices()
  })
}
