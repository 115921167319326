import { useEffect, useState } from 'react'

import { STICKY_AD_SPACING } from 'constants/ads'

export type StickyOptions = {
  offset: number
}

export type Props = {
  isSticky?: boolean
  isRailsComponent?: boolean
  parentElement?: HTMLElement | null
}

function useStickyOptions({ isSticky, isRailsComponent, parentElement }: Props) {
  const [stickyOptions, setStickyOptions] = useState<StickyOptions>()

  useEffect(() => {
    if (!document || !isSticky) {
      setStickyOptions(undefined)

      return
    }

    const headerHeight = document.querySelector<HTMLDivElement>('.js-header')?.offsetHeight || 0

    setStickyOptions({ offset: headerHeight + STICKY_AD_SPACING })
  }, [isSticky])

  useEffect(() => {
    // This is needed while we use react-on-rails
    // to output our React components
    //
    // TODO: Remove after we move to next.js

    if (!isRailsComponent || !parentElement || !stickyOptions) return

    // eslint-disable-next-line no-param-reassign
    parentElement.style.top = `${stickyOptions.offset}px`
    parentElement.classList.add('ad-sticky')
  }, [isRailsComponent, parentElement, stickyOptions])

  return stickyOptions
}

export default useStickyOptions
