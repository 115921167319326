'use client'

import { ReactNode, useCallback } from 'react'

import { AdPage } from 'constants/ads'
import { crashAdComponentEvent } from 'libs/common/event-tracker/events'
import ErrorBoundary from 'components/ErrorBoundary'
import useTracking from 'hooks/useTracking'

type Props = {
  pageName: AdPage
  placementId: string
  children: ReactNode
}

const AdErrorBoundary = ({ children, pageName, placementId }: Props) => {
  const { track } = useTracking()

  const handleErrorBoundary = useCallback(
    (error: Error | null) => {
      track(
        crashAdComponentEvent({
          error: error ? error.toString() : '',
          placementId,
          pageName,
        }),
      )
    },
    [placementId, pageName, track],
  )

  return <ErrorBoundary onError={handleErrorBoundary}>{children}</ErrorBoundary>
}

export default AdErrorBoundary
