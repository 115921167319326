'use client'

import { MouseEvent, useRef, useEffect, MutableRefObject, useMemo } from 'react'
import { Button, Cell, Text, Label, Icon, Spacer, Chip, Tabs, EmptyState } from '@vinted/web-ui'
import { Bookmark24, BookmarkFilled24, PointFilled12 } from '@vinted/monochrome-icons'
import { SavedEmptyState96 } from '@vinted/multichrome-icons'
import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'

import { toUrlQuery } from 'libs/utils/url'
import {
  clickEvent,
  toggleSearchSubscriptionEvent,
  viewSavedSearchEvent,
} from 'libs/common/event-tracker/events'
import { SortByOption } from 'constants/filter'
import { SavedSearchType } from 'constants/tracking/search'
import { Screen } from 'constants/tracking/screens'
import { searchDtoToUrlParams } from 'state/saved-searches/transformers'
import { SavedSearchDto } from 'types/dtos'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest/useAbTest'
import {
  getIsSearchLoadingMap,
  getSavedRecentSearchListId,
  getSearches,
  getSelectedSearches,
  getSelectedSection,
} from 'state/saved-searches/selectors'
import { actions } from 'state/saved-searches/slice'
import { SelectedSection } from 'state/saved-searches/constants'
import { getUserId } from 'state/session/selectors'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import SavedSearchTooltip from './SavedSearchTooltip'

type RenderSavedSearchArgs = {
  search: SavedSearchDto
  index: number
}

type Props = {
  searchUrl: string
  highlightedIndex: number | null
  searchSessionId: string
  globalSearchSessionId: string | null
  onSearchClick?: ((index: number, search: SavedSearchDto, event: MouseEvent) => void) | null
  onSubscribeClick?: ((index: number, search: SavedSearchDto, event: MouseEvent) => void) | null
  savedRecentSearchSessionId: MutableRefObject<string>
  seenSubscribedSearches: MutableRefObject<Set<number>>
  seenRecentSearches: MutableRefObject<Set<number>>
}

const SavedSearchesList = ({
  searchUrl,
  highlightedIndex,
  searchSessionId,
  globalSearchSessionId,
  onSearchClick,
  onSubscribeClick,
  savedRecentSearchSessionId,
  seenSubscribedSearches,
  seenRecentSearches,
}: Props) => {
  const translate = useTranslate('saved_searches')
  const { track } = useTracking()
  const dispatch = useDispatch()

  const userId = useSelector(getUserId)
  const savedRecentSearchListId = useSelector(getSavedRecentSearchListId)
  const isSearchLoadingMap = useSelector(getIsSearchLoadingMap)

  const searches = useSelector(getSelectedSearches)
  const allSearches = useSelector(getSearches)
  const selectedSection = useSelector(getSelectedSection)

  const showSubscribedSearchesDot = useMemo(() => {
    return allSearches
      .filter(search => search.subscribed)
      .some(search => search.new_items_count > 0)
  }, [allSearches])

  const setSelectedSection = (section: SelectedSection) => {
    dispatch(actions.setSelectedSection(section))
  }

  const setSectionRecent = () => {
    track(
      clickEvent({
        target: ClickableElement.RecentSearchTab,
      }),
    )

    setSelectedSection(SelectedSection.Recent)
  }

  const setSectionSubscribed = () => {
    track(
      clickEvent({
        target: ClickableElement.SubscribedSearchTab,
      }),
    )

    setSelectedSection(SelectedSection.Subscribed)
  }

  const onClickSeparationTab = ({ id }: { id: number | string }) => {
    if (id === 1) setSectionRecent()
    else setSectionSubscribed()
  }

  const buyerHoldoutAbTest = useAbTest({ abTestName: 'buyer_domain_holdout_2024q3' })

  const searchProminenceAbTest = useAbTest({
    abTestName: 'saved_searches_new_items_count_prominance',
    shouldTrackExpose: searches.length > 0 && buyerHoldoutAbTest?.variant !== 'off',
  })

  const searchProminenceVariant =
    buyerHoldoutAbTest?.variant === 'off' ? undefined : searchProminenceAbTest?.variant

  const separationAbTest = useAbTest({
    abTestName: 'saved_searches_separation',
    shouldTrackExpose: buyerHoldoutAbTest?.variant !== 'off',
  })

  const separationVariant =
    buyerHoldoutAbTest?.variant === 'off' ? undefined : separationAbTest?.variant

  const isSeparationOn = separationVariant && separationVariant !== 'off'

  const activeSavedSearchRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!userId) return

    dispatch(actions.fetchSubscribedSearchesRequest({ userId }))
  }, [dispatch, selectedSection, userId])

  useEffect(() => {
    activeSavedSearchRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [highlightedIndex])

  const showEmptyState = selectedSection !== SelectedSection.Recent && searches.length === 0

  const getI18nNewText = (count: number) => translate('new_text', { count }, { count })

  const onItemClick = (index: number, search: SavedSearchDto) => (event: MouseEvent) => {
    if (onSearchClick) onSearchClick(index, search, event)
  }

  const onSearchSubscribeClick = (index: number, search: SavedSearchDto) => (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    track(
      toggleSearchSubscriptionEvent({
        savedRecentSearchSessionId: savedRecentSearchSessionId.current,
        listName: selectedSection,
        savedRecentSearchListId,
        screen: Screen.SearchItems,
        position: index + 1,
        searchSessionId,
        isSubscribing: !search.subscribed,
        searchTitle: search.title,
        searchQuery: search.search_text,
        globalSearchSessionId,
      }),
    )

    if (onSubscribeClick) onSubscribeClick(index, search, event)
  }

  const getOnViewSavedSearch = (index: number, search: SavedSearchDto) => (inView: boolean) => {
    if (!inView) return

    if (selectedSection === SelectedSection.Recent) {
      if (seenRecentSearches.current.has(search.id)) return
      seenRecentSearches.current.add(search.id)
    } else {
      if (seenSubscribedSearches.current.has(search.id)) return
      seenSubscribedSearches.current.add(search.id)
    }

    track(
      viewSavedSearchEvent({
        listName: selectedSection,
        savedRecentSearchListId,
        savedRecentSearchSessionId: savedRecentSearchSessionId.current,
        screen: Screen.SearchItems,
        position: index + 1,
        newItemsCount: search.new_items_count,
        searchTitle: search.title,
        unrestrictedNewItemsCount: search.unrestricted_new_items_count,
        searchSessionId,
        globalSearchSessionId,
        type: search.subscribed ? SavedSearchType.SubscribedSearch : SavedSearchType.RecentSearch,
      }),
    )
  }

  function renderSavedSearchSuffix(index: number, search: SavedSearchDto) {
    return (
      <div className="u-position-relative u-zindex-bump">
        <Button
          icon={
            <Icon
              name={search.subscribed ? BookmarkFilled24 : Bookmark24}
              color={search.subscribed ? Icon.Color.Primary : Icon.Color.GreyscaleLevel1}
              aria={{
                'aria-label': search.subscribed
                  ? translate('a11y.remove_saved_search', { title: search.title })
                  : translate('a11y.save_search', { title: search.title }),
              }}
            />
          }
          styling={Button.Styling.Flat}
          theme="muted"
          testId="subscription-toggle"
          onClick={onSearchSubscribeClick(index, search)}
          isLoading={isSearchLoadingMap[search.id]}
        />
      </div>
    )
  }

  function renderSavedSearchTitle(title: string, newItemsCount: number | null) {
    const showNewItemsCount = !!newItemsCount && searchProminenceVariant !== 'a'

    const getNewItemsText = (count: number) => {
      if (searchProminenceVariant === 'b') {
        return `+${getI18nNewText(count)}`
      }

      return `+${count}`
    }

    return (
      <div className="u-flexbox">
        {showNewItemsCount && (
          <div className="u-ui-padding-right-small u-no-wrap">
            <Text text={getNewItemsText(newItemsCount)} theme="primary" />
          </div>
        )}

        <span className="u-ellipsis u-flex-1">{title}</span>
      </div>
    )
  }

  const renderDotIndicator = () => (
    <Icon testId="dot-indicator" color={Icon.Color.Warning} name={PointFilled12} />
  )

  const renderEmptyState = () => {
    if (!showEmptyState) return null

    const title = translate('separation.empty_state.title')
    const body = translate('separation.empty_state.subtitle')

    return (
      <EmptyState
        testId="saved-searches-empty-state"
        icon={<Icon color={Icon.Color.Primary} name={SavedEmptyState96} />}
        title={title}
        body={body}
      />
    )
  }

  const renderSavedSearch = ({ search, index }: RenderSavedSearchArgs) => {
    const { id, title, subtitle, new_items_count: newItemsCount } = search
    const params = {
      ...searchDtoToUrlParams(search),
      search_id: id,
      order: SortByOption.NewestFirst,
    }
    const url = `${searchUrl}?${toUrlQuery(params)}`

    const isHighlighted = index === highlightedIndex

    return (
      <div ref={isHighlighted ? activeSavedSearchRef : null}>
        <Cell
          type={Cell.Type.Navigating}
          title={renderSavedSearchTitle(title, newItemsCount)}
          suffix={renderSavedSearchSuffix(index, search)}
          highlighted={isHighlighted}
          url={url}
          onClick={onItemClick(index, search)}
          aria={{
            'aria-label': search.title,
          }}
        >
          {!!subtitle && <Text text={subtitle} truncate />}
          {searchProminenceVariant === 'a' && (
            <>
              <Spacer />
              <Text
                text={`${newItemsCount > 0 ? '+' : ''}${getI18nNewText(newItemsCount)}`}
                theme={newItemsCount === 0 ? 'muted' : 'primary'}
              />
            </>
          )}
        </Cell>
      </div>
    )
  }

  const renderYourSearchesLabel = () => (
    <>
      <Label text={translate('list.title')} type={Label.Type.Stacked} />
      <Spacer />
    </>
  )

  function renderSavedSearches() {
    const recentSearchesText = translate('separation.recent')
    const subscribedSearchesText = translate('separation.subscribed')

    return (
      <div className="u-position-relative">
        <div className="u-sticky-top u-zindex-large">
          {separationVariant === 'a' && (
            <Cell>
              <div className="u-flexbox">
                <Chip
                  testId="recent-searches-scope"
                  onClick={setSectionRecent}
                  text={recentSearchesText}
                  textType={Text.Type.Subtitle}
                  activated={selectedSection === SelectedSection.Recent}
                />

                <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />

                <SavedSearchTooltip>
                  <Chip
                    testId="subscribed-searches-scope"
                    suffix={showSubscribedSearchesDot && renderDotIndicator()}
                    onClick={setSectionSubscribed}
                    text={subscribedSearchesText}
                    textType={Text.Type.Subtitle}
                    activated={selectedSection === SelectedSection.Subscribed}
                  />
                </SavedSearchTooltip>
              </div>
            </Cell>
          )}

          {separationVariant === 'b' && (
            <Tabs
              activeItemId={selectedSection === SelectedSection.Recent ? 1 : 2}
              onClick={onClickSeparationTab}
              items={[
                {
                  id: 1,
                  title: <span data-testid="recent-searches-scope">{recentSearchesText}</span>,
                },
                {
                  id: 2,
                  children: (
                    <SavedSearchTooltip>
                      <div
                        data-testid="subscribed-searches-scope"
                        className="u-flexbox u-gap-small u-align-items-center"
                      >
                        <Tabs.TabTitle>{subscribedSearchesText}</Tabs.TabTitle>
                        {showSubscribedSearchesDot && renderDotIndicator()}
                      </div>
                    </SavedSearchTooltip>
                  ),
                },
              ]}
            />
          )}

          {isSeparationOn && !showEmptyState && renderYourSearchesLabel()}
        </div>

        {!isSeparationOn && !showEmptyState && renderYourSearchesLabel()}

        {searches.map((search, index) => (
          <InView
            key={`${search.id}-${selectedSection}`}
            onChange={getOnViewSavedSearch(index, search)}
          >
            {renderSavedSearch({ search, index })}
          </InView>
        ))}
      </div>
    )
  }

  return (
    <div className="saved-searches">
      <Cell styling={Cell.Styling.Tight} testId="saved-searches">
        <div className="saved-searches__content">
          {renderSavedSearches()}
          {renderEmptyState()}
        </div>
      </Cell>
    </div>
  )
}

export default SavedSearchesList
