'use client'

import { createRef, Component, KeyboardEvent, ReactNode } from 'react'

import { KeyboardKey } from 'constants/keyboard'

export type RenderProps = {
  highlightedResultIndex: number | null
  focusResultsContainer?: () => void
  handleKeyNavigation: (event: KeyboardEvent) => void
}

type Props = {
  highlightedResultIndex: number | null
  itemCount: number
  onSelect?: (selectedIndex: number, event: KeyboardEvent) => void
  children: (props: RenderProps) => ReactNode
  onHighlightedResultChange?: (highlightedResultIndex: number | null) => void
}

type State = {
  highlightedResultIndex: number | null
}

const LOWER_LIMIT = 0

class ListNavigator extends Component<Props, State> {
  static defaultProps = {
    highlightedResultIndex: null,
  }

  state: Readonly<State> = {
    highlightedResultIndex: this.props.highlightedResultIndex,
  }

  divRef = createRef<HTMLDivElement>()

  focusResultsContainer() {
    const { current: resultsContainer } = this.divRef

    if (!resultsContainer) return

    resultsContainer.focus()
  }

  handleKeyNavigation = (event: KeyboardEvent) => {
    const { itemCount, onSelect, onHighlightedResultChange } = this.props
    const { highlightedResultIndex } = this.state
    const { key } = event
    const upperLimit = itemCount - 1

    if (!itemCount) return

    switch (key) {
      case KeyboardKey.ArrowDown:
        event.preventDefault()

        this.setHighlightedSearchResult(
          (highlightedResultIndex ?? LOWER_LIMIT - 1) < upperLimit,
          1,
          LOWER_LIMIT,
          onHighlightedResultChange,
        )
        break
      case KeyboardKey.ArrowUp:
        event.preventDefault()

        this.setHighlightedSearchResult(
          (highlightedResultIndex ?? upperLimit + 1) > LOWER_LIMIT,
          -1,
          upperLimit,
          onHighlightedResultChange,
        )
        break
      case KeyboardKey.Enter:
        if (highlightedResultIndex === null) break

        event.preventDefault()

        if (onSelect) onSelect(highlightedResultIndex, event)
        break
      default:
        this.resetHighlightedResult()
    }
  }

  setHighlightedSearchResult(
    isValueInRange: boolean,
    modifier: number,
    nullModifier: number,
    callback?: (index: number | null) => void,
  ) {
    if (isValueInRange) {
      this.setState(
        prevState => ({
          highlightedResultIndex:
            prevState.highlightedResultIndex === null
              ? nullModifier
              : prevState.highlightedResultIndex + modifier,
        }),
        () => callback?.(this.state.highlightedResultIndex),
      )
    } else {
      this.resetHighlightedResult()
    }
  }

  resetHighlightedResult() {
    this.setState({ highlightedResultIndex: null })
  }

  render() {
    const { highlightedResultIndex } = this.state

    const renderChildrenWithProps = this.props.children({
      highlightedResultIndex,
      focusResultsContainer: this.focusResultsContainer,
      handleKeyNavigation: this.handleKeyNavigation,
    })

    return renderChildrenWithProps
  }
}

export default ListNavigator
