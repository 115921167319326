import { useSelector } from 'react-redux'

import { getScreenName } from 'state/screen/selectors'
import { getCountryCode } from 'state/session/selectors'

import { requestedAdEvent } from 'libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking/useTracking'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

function useRequestedAdLogging(placementId: string) {
  const screen = useSelector(getScreenName)
  const countryCode = useSelector(getCountryCode)

  const isRequestedAdLoggingEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_requested_ad_logging'),
  )

  const { track } = useTracking()

  const onRequest = () => {
    if (!isRequestedAdLoggingEnabled) return

    track(
      requestedAdEvent({
        placementId,
        screen,
        countryCode,
      }),
    )
  }

  return { onRequest }
}

export default useRequestedAdLogging
