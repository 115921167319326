'use client'

import { MouseEvent } from 'react'
import { Badge, Cell, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { CatalogAttribute, WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'
import { CatalogModalContent, CatalogWithoutParentIdModel } from 'types/models'

type Props = {
  selectedCategory: CatalogWithoutParentIdModel
  selectedCategoryId: string | number
  triggerCatalogModal: (modalContent: CatalogModalContent) => void
}

const CatalogsPanel = ({ selectedCategory, selectedCategoryId, triggerCatalogModal }: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const parentCatalog = {
    ...selectedCategory,
    code: WEB_CATALOG_ROOT_ALL_CODE,
    title: translate('header.main_navigation.catalogs.see_all'),
  }

  const catalogList: Array<CatalogWithoutParentIdModel> = [
    parentCatalog,
    ...selectedCategory.catalogs,
  ]

  const handleTitleClick =
    ({ id, title, landing }: CatalogWithoutParentIdModel) =>
    (event: MouseEvent<Element>) => {
      if (!landing) {
        const trakingEvent = selectCatalogEvent({
          catalogIds: [id],
          attributeId: CatalogAttribute.Navigation,
        })

        track(trakingEvent)

        return
      }

      event.preventDefault()
      triggerCatalogModal({
        catalogName: title,
        itemsRemaining: landing.itemsRemaining,
        imageUrl: landing.imageUrl,
      })
    }

  const renderCatalogItem = (item: CatalogWithoutParentIdModel) => (
    <div key={item.id} className="catalog-dropdown__item">
      <Cell
        suffix={item.landing && <Badge theme="primary" content={translate('common.new_badge')} />}
        type={Cell.Type.Navigating}
        styling={Cell.Styling.Narrow}
        body={<Text truncate>{item.title}</Text>}
        url={item.url}
        urlProps={{ title: item.title }}
        onClick={handleTitleClick(item)}
        testId={`catalog-item-${item.id}`}
        aria={{ 'aria-label': item.title }}
      />
    </div>
  )

  return (
    <div
      className={classNames('catalog-dropdown__category', {
        active: selectedCategory.id === selectedCategoryId,
      })}
    >
      {catalogList.map(renderCatalogItem)}
    </div>
  )
}

export default CatalogsPanel
