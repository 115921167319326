'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Divider, Image, Rating, Spacer, Text } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useUserAgent from 'hooks/useUserAgent'

import { SECONDS_PER_DAY } from 'constants/date'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { abTestExposeEvent, clickEvent } from 'libs/common/event-tracker/events'
import { getCookie, saveCookie } from 'libs/utils/cookies'
import { relativeDate } from 'libs/utils/date'
import * as device from 'libs/utils/userAgentParser'

import { getScreenName } from 'state/screen/selectors'
import { getExposee, getUserId } from 'state/session/selectors'
import { getAbTestByName } from 'state/ab-tests/selectors'

import { PageId } from 'types/page-id'
import useIsInPage from 'hooks/useIsInPage'

import useClientSideOnly from '../../../next/hooks/useClientSideOnly'

// It is decided to have hardcoded app rating with no need to sync it with store
const APP_RATING = 4.5
const APP_URL =
  'https://4uwb.adj.st/?adjust_t=l2fpdc8_rnxf4nj&adjust_campaign=UX_SmartBanner&adjust_engagement_type=fallback_click'
const COOKIE_NAME = 'app_banner'
const COOKIE_EXPIRATION_TIME = SECONDS_PER_DAY * 5

const LATEST_ALLOWED_ANDROID_VERSION = 7

const appBannerPageIds: Array<PageId> = ['root', 'member', 'catalog', 'catalog-by-id', 'brand']

type Props = {
  isHeader?: boolean
}

const AppBanner = ({ isHeader }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('app_banner')
  const userAgent = useUserAgent()
  const userId = useSelector(getUserId)
  const screenName = useSelector(getScreenName)
  const webToAppAbTest = useSelector(getAbTestByName('web_2_app_conversion_v1'))
  const exposee = useSelector(getExposee)
  const asset = useAsset('/assets/logo/square')
  const isClientSide = useClientSideOnly()

  const getAndroidVersion = () => {
    const match = userAgent.toLowerCase().match(/android\s([0-9.]*)/i)

    return match ? parseInt(match[1], 10) : LATEST_ALLOWED_ANDROID_VERSION
  }

  const shouldNotRenderOnMobile = () =>
    !device.isIOS(userAgent) &&
    !(device.isAndroid(userAgent) && getAndroidVersion() >= LATEST_ALLOWED_ANDROID_VERSION)

  const [isHidden, setIsHidden] = useState(false)

  const isInPage = useIsInPage(appBannerPageIds)

  useEffect(() => {
    if (!webToAppAbTest) return

    track(abTestExposeEvent({ ...exposee, ...webToAppAbTest }))
  }, [webToAppAbTest, exposee, track])

  if (!isClientSide) return null
  if (isHidden) return null
  if (userId) return null
  if (getCookie(COOKIE_NAME)) return null
  if (shouldNotRenderOnMobile()) return null
  if (!isInPage) return null

  if (webToAppAbTest?.variant === 'on' && !isHeader && screenName === Screen.NewsFeed) return null

  function trackClick(target: ClickableElement) {
    track(
      clickEvent({
        target,
        targetDetails: device.parseOS(userAgent),
      }),
    )
  }

  function handleOpenClick() {
    trackClick(ClickableElement.InstallAppBanner)
  }

  function handleCloseClick() {
    setIsHidden(true)
    trackClick(ClickableElement.CancelInstallAppBanner)
    saveCookie(COOKIE_NAME, 'hidden', relativeDate({ seconds: COOKIE_EXPIRATION_TIME }))
  }

  function renderActions() {
    return (
      <div className="u-flexbox">
        <Button
          size={Button.Size.Medium}
          text={translate('close_cta')}
          onClick={handleCloseClick}
          testId="app-banner-close-button"
        />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Button
          theme="primary"
          styling={Button.Styling.Filled}
          size={Button.Size.Medium}
          text={translate('cta')}
          onClick={handleOpenClick}
          url={APP_URL}
          testId="app-banner-open-button"
        />
      </div>
    )
  }

  function renderTabletActions() {
    return <div className="u-tablets-only">{renderActions()}</div>
  }

  function renderPhoneActions() {
    return (
      <div className="u-phones-only">
        <Spacer size={Spacer.Size.Large} />
        <Cell styling={Cell.Styling.Tight}>{renderActions()}</Cell>
      </div>
    )
  }

  function renderTitle() {
    return (
      <div className="u-flexbox">
        <Text type={Text.Type.Title} text={translate('title')} truncate />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <div>
          <Spacer size={Spacer.Size.XSmall} />
          <Rating value={APP_RATING} />
        </div>
      </div>
    )
  }

  return (
    <div className="app-banner">
      <div
        className={isHeader && webToAppAbTest?.variant === 'on' ? 'sticky-header' : 'sticky-footer'}
        data-testid="app-banner-wrapper"
      >
        <div className="u-line-height-default">
          {!isHeader && <Divider />}
          <Cell>
            <Cell
              styling={Cell.Styling.Tight}
              prefix={<Image size={Image.Size.Large} src={asset('transparent.png')} />}
              suffix={renderTabletActions()}
              title={renderTitle()}
              body={<Text text={translate('description')} truncate />}
              fullWidthTitle
            />

            {renderPhoneActions()}
          </Cell>
          {isHeader && <Divider />}
        </div>
      </div>
    </div>
  )
}

export default AppBanner
