import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { loadAdsPlacements } from 'state/ads/actions'
import usePageId from 'hooks/usePageId'

// From the first look it might seem that it only
// dispatches action to call `loadAdsPlacements`
// saga. And that is true, but that is a starting
// point for a bigger things to come.
//
// Ideally, this Provider should be able to Provide a
// Context that would hold information if ads need
// to be shown at all. Also, it could handle certain
// procedures that are currently handled in ad-manager
// to make setup faster and more dummy-proof.
//
// Also, ideally, it should also hold ad placements
// fetch logic so we could abandon using redux for
// ad placements.

const AdsProvider = ({ children }) => {
  const pageId = usePageId()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAdsPlacements({ pageId }))
  }, [dispatch, pageId])

  return children
}

export default AdsProvider
