import { useMemo } from 'react'

import ConsentContext from './ConsentContext'

type Props = {
  children: React.ReactNode
  serverCookieOptanonConsent?: string
}

const ConsentProvider = ({ children, serverCookieOptanonConsent = '' }: Props) => {
  const value = useMemo(() => ({ serverCookieOptanonConsent }), [serverCookieOptanonConsent])

  return <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>
}

export default ConsentProvider
