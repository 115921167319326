'use client'

import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Divider, InputBar, Icon } from '@vinted/web-ui'
import { Search16 } from '@vinted/monochrome-icons'
import { isEmpty, trim } from 'lodash'
import uuid from 'uuid'

import { SEARCH_SUGGESTION_MIN_QUERY_LENGTH } from 'constants/index'
import { CATALOG_URL, HELP_SEARCH_URL, USER_SEARCH_URL } from 'constants/routes'
import { SearchBarSearchType, SearchSuggestionType } from 'constants/search'
import { MS_PER_SECOND, SECONDS_PER_DAY } from 'constants/date'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { SavedSearchType, SearchStartType } from 'constants/tracking/search'

import OutsideClick from 'components/OutsideClick'
import ListNavigator from 'components/ListNavigator'
import { SavedSearchesList, SearchSubscribeModal } from 'components/SavedSearches'
import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import {
  getIsSubscribedModalOpen,
  getSavedRecentSearchListId,
  getSearches,
  getSelectedSearches,
  getSelectedSection,
} from 'state/saved-searches/selectors'
import { searchDtoToUrlParams } from 'state/saved-searches/transformers'
import { fetchSearchesRequest, toggleSearchSubscription } from 'state/saved-searches/actions'
import { actions } from 'state/saved-searches/slice'
import {
  getGlobalSearchSessionId,
  getSearchSessionId,
  getSelectedCatalogs,
} from 'state/catalog-filters/selectors'
import { actions as filterActions } from 'state/catalog-filters/slice'
import { searchSuggestionToFilters, searchSuggestionToUrlParams } from 'state/catalog-filters/utils'
import { getUserCountryCode, getUserId, getCountryCode } from 'state/session/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import {
  clickEvent,
  selectSavedSearchEvent,
  selectSearchSuggestionEvent,
  SelectSearchSuggestionEventArgs,
} from 'libs/common/event-tracker/events'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

import { redirectToPage } from 'libs/utils/window'
import { normalizedQueryParam, toUrlQuery } from 'libs/utils/url'
import { setSearchStartData } from 'libs/utils/search'
import { SavedSearchDto } from 'types/dtos'
import { SearchSuggestionModel } from 'types/models'
import { Screen } from 'constants/tracking/screens'
import { SelectedSection } from 'state/saved-searches/constants'

import TypeSelector from './TypeSelector'
import SearchSuggestions, { useSearchSuggestions } from './SearchSuggestions'

const SEARCH_URLS: Record<SearchBarSearchType, string> = {
  [SearchBarSearchType.Item]: CATALOG_URL,
  [SearchBarSearchType.User]: USER_SEARCH_URL,
  [SearchBarSearchType.Faq]: HELP_SEARCH_URL,
}

const MAX_QUERY_LENGTH = 5_000

const isSavedSearchesRendered = (query: string) =>
  isEmpty(query) || query.length < SEARCH_SUGGESTION_MIN_QUERY_LENGTH

type Props = {
  searchType?: SearchBarSearchType
  isInCatalog: boolean
}

const SearchBar = ({
  searchType: initialSearchType = SearchBarSearchType.Item,
  isInCatalog = false,
}: Props) => {
  const { searchParams } = useLocation()
  const translate = useTranslate()
  const { track } = useTracking()
  const dispatch = useDispatch()
  const cookies = useCookie()

  const userId = useSelector(getUserId)
  const isSubscribeModalOpen = useSelector(getIsSubscribedModalOpen)
  const selectedCatalogs = useSelector(getSelectedCatalogs)
  const userCountryCode = useSelector(getUserCountryCode)
  const countryCode = useSelector(getCountryCode)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const savedRecentSearchListId = useSelector(getSavedRecentSearchListId)
  const allSearches = useSelector(getSearches)
  const selectedSearches = useSelector(getSelectedSearches)
  const selectedListSection = useSelector(getSelectedSection)

  const isSearchSuggestionsAutofillEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_search_suggestions_autofill_when_moving_arrows'),
  )

  const initialQuery = normalizedQueryParam(searchParams.search_text)

  const [query, setQuery] = useState(initialQuery)
  const [searchType, setSearchType] = useState(initialSearchType)
  const [isFocused, setIsFocused] = useState(false)
  const [isTypeSelectorOpen, setIsTypeSelectorOpen] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState<null | string>(null)

  const { fetchSuggestions, clearSuggestions, suggestionsListId, suggestions } =
    useSearchSuggestions(query)

  const inputRef = useRef<HTMLInputElement>(null)
  const isSavedSearchesFetched = useRef(false)

  const suggestionsSessionId = useRef(uuid.v4())
  const savedRecentSearchSessionId = useRef(uuid.v4())

  const seenSuggestions = useRef<Set<number>>(new Set())
  const seenSubscribedSearches = useRef<Set<number>>(new Set())
  const seenRecentSearches = useRef<Set<number>>(new Set())
  const sentSelectSearchIds = useRef<Set<number>>(new Set())

  useEffect(() => {
    if (isFocused) return

    dispatch(actions.setSelectedSection(SelectedSection.Recent))
  }, [dispatch, isFocused])

  useEffect(() => {
    if (isFocused) return

    suggestionsSessionId.current = uuid.v4()
    savedRecentSearchSessionId.current = uuid.v4()
    seenSuggestions.current.clear()
    seenSubscribedSearches.current.clear()
    seenRecentSearches.current.clear()

    dispatch(actions.genSavedRecentSearchListIds())
  }, [isFocused, dispatch])

  const requestSearches = () => {
    if (!userId) return

    dispatch(fetchSearchesRequest({ userId }))

    isSavedSearchesFetched.current = true
  }

  const querySearchSuggestions = (queryInput: string) => {
    if (searchType !== SearchBarSearchType.Item || isEmpty(queryInput)) return

    if (!isSavedSearchesRendered(queryInput)) {
      fetchSuggestions(queryInput)
    }
  }

  const focusInput = () => inputRef.current?.focus()

  const blurInput = () => inputRef.current?.blur()

  const toggleTypeSelector = () => setIsTypeSelectorOpen(prevState => !prevState)

  const searchSuggestionUrl = (suggestion: SearchSuggestionModel) => {
    const { item: itemSearchUrl } = SEARCH_URLS

    if (isInCatalog) return undefined

    if (suggestion.type === SearchSuggestionType.Scoped) {
      const filterQuery = toUrlQuery(searchSuggestionToUrlParams(suggestion))

      return `${itemSearchUrl}?${filterQuery}`
    }

    return `${itemSearchUrl}?search_text=${encodeURIComponent(suggestion.query)}`
  }

  const getPlaceholder = () => {
    const isCatalogPlaceholder = isInCatalog && selectedCatalogs.length

    if (searchType === SearchBarSearchType.Item && isCatalogPlaceholder) {
      const catalogName = selectedCatalogs.map(catalog => catalog.title).join(', ')

      return translate(`searchbar.placeholder.${searchType}_with_catalog_selected`, {
        catalog: catalogName,
      })
    }

    return translate(`searchbar.placeholder.${searchType}`)
  }

  const handleQueryChange = (
    event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
  ) => {
    const newQuery = event.currentTarget.value

    if (!isEmpty(trim(newQuery))) {
      querySearchSuggestions(newQuery)
    }

    setQuery(newQuery)
  }

  const handleValueClear = () => setQuery('')

  const handleTypeSelectorClick = () => {
    setIsFocused(false)
    toggleTypeSelector()

    track(clickEvent({ target: ClickableElement.SearchBarType }))
  }

  const handleTypeSelectorSelect = (newSearchType: SearchBarSearchType) => {
    toggleTypeSelector()
    focusInput()

    if (searchType !== newSearchType) setSearchType(newSearchType)
  }

  const handleOutClick = () => {
    // Prevent excessive re-rendering
    if (isSubscribeModalOpen || !(isTypeSelectorOpen || isFocused)) return

    setIsTypeSelectorOpen(false)
    setIsFocused(false)
  }

  const handleInputFocus = () => {
    if (!isSavedSearchesFetched.current) requestSearches()

    if (!isSavedSearchesRendered(query) && !isFocused) {
      querySearchSuggestions(query)
    }

    setIsTypeSelectorOpen(false)
    setIsFocused(true)
  }

  const handleInputClick = () => track(clickEvent({ target: ClickableElement.SearchBar }))

  const handleSubscribeClick = (_index: number, search: SavedSearchDto) => {
    if (!userId) return

    dispatch(toggleSearchSubscription({ userId, searchId: search.id }))
  }

  const handleSubscribeModalClose = () => {
    dispatch(actions.closeSubscribeModal())
  }

  const handleSuggestionClick = (
    suggestion: SearchSuggestionModel,
    index: number,
    scopedSuggestionCount: number,
  ) => {
    const trackingArgs: SelectSearchSuggestionEventArgs = {
      suggestions: suggestions.map(item => item.query),
      suggestion: suggestion.query,
      query,
      scopedSuggestionCount,
      index,
      countryCode: userCountryCode || countryCode,
      searchSessionId,
      globalSearchSessionId,
      suggestionsSessionId: suggestionsSessionId.current,
      isFrontendGeneratedSuggestion: suggestion.type === SearchSuggestionType.Fallback,
    }

    if (suggestion.type === SearchSuggestionType.Scoped) {
      trackingArgs.subtitle = suggestion.subtitle
    }

    if ('params' in suggestion) {
      trackingArgs.params = suggestion.params
    }

    trackingArgs.suggestionPosition = index + 1
    trackingArgs.suggestionsListId = suggestionsListId.current

    track(selectSearchSuggestionEvent(trackingArgs))

    setSearchStartData({
      searchStartId: suggestionsSessionId.current,
      searchStartType: SearchStartType.SearchSuggestions,
    })

    if (isInCatalog) {
      if (suggestion.type === SearchSuggestionType.Scoped) {
        dispatch(filterActions.setFilters({ filters: searchSuggestionToFilters(suggestion) }))
      }

      dispatch(filterActions.setQuery({ query: suggestion.query }))
      dispatch(filterActions.submitSearchQuery({ query: suggestion.query }))
    }

    setQuery(suggestion.query)
    setIsFocused(false)
  }

  const handleSavedSearchClick = (index: number, savedSearch: SavedSearchDto) => {
    const savedSearchType = savedSearch.subscribed
      ? SavedSearchType.SubscribedSearch
      : SavedSearchType.RecentSearch

    const searchStartType = savedSearch.subscribed
      ? SearchStartType.SubscribedSearch
      : SearchStartType.RecentSearch

    setSearchStartData({
      searchStartId: savedRecentSearchSessionId.current,
      searchStartType,
    })

    if (sentSelectSearchIds.current.has(savedSearch.id)) return
    sentSelectSearchIds.current.add(savedSearch.id)

    track(
      selectSavedSearchEvent({
        listName: selectedListSection,
        savedRecentSearchListId,
        savedRecentSearchSessionId: savedRecentSearchSessionId.current,
        screen: Screen.SearchItems,
        position: index + 1,
        searchSessionId,
        type: savedSearchType,
        searchTitle: savedSearch.title,
        globalSearchSessionId,
        newItemsCount: savedSearch.new_items_count,
        unrestrictedNewItemsCount: savedSearch.unrestricted_new_items_count,
      }),
    )
  }

  const handleSubmit = (event: FormEvent) => {
    if (searchType === SearchBarSearchType.Faq) {
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + SECONDS_PER_DAY * MS_PER_SECOND)

      cookies.set(cookiesDataByName.help_center_search_session_id, uuid.v4())
    }

    if (searchType !== SearchBarSearchType.Item) return
    if (isInCatalog) event.preventDefault()

    setSearchStartData({
      searchStartId: suggestionsSessionId.current,
      searchStartType: SearchStartType.SearchManual,
    })

    blurInput()
    setIsFocused(false)
    dispatch(filterActions.setQuery({ query }))
    dispatch(filterActions.submitSearchQuery({ query }))
  }

  const handleSavedSearchOnEnter = (highlightedResultIndex: number) => {
    const highlightedSearch = selectedSearches[highlightedResultIndex]
    const params = { ...searchDtoToUrlParams(highlightedSearch), search_id: highlightedSearch.id }
    const url = `${SEARCH_URLS[SearchBarSearchType.Item]}?${toUrlQuery(params)}`

    handleSavedSearchClick(highlightedResultIndex, highlightedSearch)
    redirectToPage(url)
    blurInput()
  }

  const handleSearchSuggestionOnEnter = (highlightedResultIndex: number) => {
    const highlightedSuggestion = suggestions[highlightedResultIndex]
    const url = searchSuggestionUrl(highlightedSuggestion)
    const scopedSuggestions = suggestions.filter(item => item.type === SearchSuggestionType.Scoped)

    handleSuggestionClick(highlightedSuggestion, highlightedResultIndex, scopedSuggestions.length)

    if (url) redirectToPage(url)

    blurInput()
  }

  const handleSuggestionNavigation = (highlightedResultIndex: number | null) => {
    if (highlightedResultIndex === null) {
      setSelectedSearch(null)
    } else if (isSavedSearchesRendered(query)) {
      setSelectedSearch(selectedSearches[highlightedResultIndex].search_text ?? null)
    } else {
      setSelectedSearch(suggestions[highlightedResultIndex].query)
    }
  }

  const renderIcon = () => (
    <div className="u-flexbox u-align-items-center">
      <Icon name={Search16} color={Icon.Color.GreyscaleLevel4} />
    </div>
  )

  const renderPrefix = () => (
    <div className="u-flexbox u-fill-height">
      <TypeSelector
        selectedType={searchType}
        isOpen={isTypeSelectorOpen}
        onClick={handleTypeSelectorClick}
        onSelect={handleTypeSelectorSelect}
      />
      <Divider orientation={Divider.Orientation.Vertical} />
    </div>
  )

  const renderSuggestions = (highlightedResultIndex: number | null) => (
    <SearchSuggestions
      query={query}
      items={suggestions}
      highlightedIndex={highlightedResultIndex}
      onSuggestionClick={handleSuggestionClick}
      suggestionUrl={searchSuggestionUrl}
      searchSessionId={searchSessionId}
      globalSearchSessionId={globalSearchSessionId}
      suggestionsListId={suggestionsListId}
      suggestionsSessionId={suggestionsSessionId}
      seenSuggestions={seenSuggestions}
      onClearSuggestions={clearSuggestions}
    />
  )

  const renderSavedSearches = (highlightedResultIndex: number | null) => {
    if (!userId || !allSearches.length) return null

    return (
      <SavedSearchesList
        highlightedIndex={highlightedResultIndex}
        onSubscribeClick={handleSubscribeClick}
        searchUrl={SEARCH_URLS[SearchBarSearchType.Item]}
        searchSessionId={searchSessionId}
        globalSearchSessionId={globalSearchSessionId}
        onSearchClick={handleSavedSearchClick}
        savedRecentSearchSessionId={savedRecentSearchSessionId}
        seenSubscribedSearches={seenSubscribedSearches}
        seenRecentSearches={seenRecentSearches}
      />
    )
  }

  const renderDropdown = (highlightedResultIndex: number | null) => {
    if (searchType !== SearchBarSearchType.Item || !isFocused) return null

    const dropdownContent = isSavedSearchesRendered(query)
      ? renderSavedSearches(highlightedResultIndex)
      : renderSuggestions(highlightedResultIndex)

    if (isEmpty(dropdownContent)) return null

    return (
      <div className="u-ui-margin-top-regular u-position-absolute u-fill-width">
        <Card styling={Card.Styling.Lifted}>
          <div className="u-overflow-hidden">{dropdownContent}</div>
        </Card>
      </div>
    )
  }

  const renderAccessChannel = () => {
    if (searchType !== 'faq') return null

    return <input type="hidden" name="access_channel" value="hc_search" />
  }

  let resultsCount = selectedSearches.length
  let onSelect = handleSavedSearchOnEnter

  if (!isSavedSearchesRendered(query)) {
    resultsCount = suggestions.length
    onSelect = handleSearchSuggestionOnEnter
  }

  return (
    <OutsideClick onOutsideClick={handleOutClick}>
      <form onSubmit={handleSubmit} method="get" action={SEARCH_URLS[searchType]}>
        {renderAccessChannel()}
        <ListNavigator
          itemCount={resultsCount}
          onSelect={onSelect}
          onHighlightedResultChange={
            isSearchSuggestionsAutofillEnabled ? handleSuggestionNavigation : undefined
          }
        >
          {({ highlightedResultIndex, handleKeyNavigation }) => (
            <div className="u-position-relative">
              <InputBar
                maxLength={MAX_QUERY_LENGTH}
                inputAria={{
                  'aria-labelledby': `search-${searchType}`,
                }}
                clearButtonAria={{
                  'aria-label': translate('searchbar.button.clear'),
                }}
                name="search_text"
                placeholder={getPlaceholder()}
                value={isSearchSuggestionsAutofillEnabled ? selectedSearch || query : query}
                icon={renderIcon()}
                prefix={renderPrefix()}
                onChange={handleQueryChange}
                onFocus={handleInputFocus}
                onKeyDown={handleKeyNavigation}
                onValueClear={handleValueClear}
                onInputClick={handleInputClick}
                ref={inputRef}
                testId="search-text"
              />

              {renderDropdown(highlightedResultIndex)}
            </div>
          )}
        </ListNavigator>

        <SearchSubscribeModal isOpen={isSubscribeModalOpen} onClose={handleSubscribeModalClose} />
      </form>
    </OutsideClick>
  )
}

export default SearchBar
