'use client'

import { useCallback, useState } from 'react'

import { NotificationCloseType } from 'components/Notification'
import useTracking from 'hooks/useTracking'
import useBrazeInAppMessage from 'libs/common/braze/hooks/useBrazeInAppMessage'

import {
  InAppModalMessageModel,
  InAppNotificationMessageModel,
} from 'libs/common/braze/types/models/in-app-message'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'

import {
  brazeLogInAppMessageButtonClick,
  brazeLogInAppMessageClick,
  brazeLogInAppMessageImpression,
} from 'libs/common/braze/utils/event-loggers'

import NotificationInAppMessage from './NotificationInAppMessage'
import ModalInAppMessage from './ModalInAppMessage'

const InAppMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  const { track } = useTracking()
  const { notificationInAppMessage, modalInAppMessage } = useBrazeInAppMessage()

  const anyInAppMessage = notificationInAppMessage ?? modalInAppMessage

  const trackDismissal = (
    inAppMessage: InAppModalMessageModel | InAppNotificationMessageModel | null,
  ) => {
    if (!inAppMessage) return

    const targetDetails = JSON.stringify({
      campaign_name: inAppMessage.campaignName,
      campaign_message_name: inAppMessage.campaignMessageName,
      canvas_name: inAppMessage.canvasName,
      canvas_variant_name: inAppMessage.canvasVariantName,
    })

    track(
      clickEvent({
        target: ClickableElement.CrmInAppMessageDismiss,
        screen: Screen.CrmInAppMessage,
        targetDetails,
      }),
    )
  }

  const closeModal = () => {
    setIsModalOpen(false)
    trackDismissal(modalInAppMessage)
  }

  const handlePrimaryButtonClick = () => {
    if (!modalInAppMessage) return

    if (!modalInAppMessage.primaryButtonUrl) closeModal()

    brazeLogInAppMessageButtonClick(modalInAppMessage, 0)
  }

  const handleSecondaryButtonClick = () => {
    if (!modalInAppMessage) return

    if (!modalInAppMessage.secondaryButtonUrl) closeModal()

    brazeLogInAppMessageButtonClick(modalInAppMessage, 1)
  }

  const handleModalLinkClick = (url: string) => {
    if (!modalInAppMessage) return

    const targetDetails = JSON.stringify({
      campaign_name: modalInAppMessage.campaignName,
      campaign_message_name: modalInAppMessage.campaignMessageName,
      canvas_name: modalInAppMessage.canvasName,
      canvas_variant_name: modalInAppMessage.canvasVariantName,
      url,
    })

    track(
      clickEvent({
        target: ClickableElement.CrmMessageLink,
        screen: Screen.CrmInAppMessage,
        targetDetails,
      }),
    )
  }

  const handleNotificationClose = (closeType: NotificationCloseType | undefined) => {
    if (notificationInAppMessage?.url || closeType === NotificationCloseType.Auto) {
      trackDismissal(notificationInAppMessage)

      return
    }

    if (notificationInAppMessage) {
      brazeLogInAppMessageClick(notificationInAppMessage)
    }
  }

  const handleNotificationLinkClick = () => {
    if (!notificationInAppMessage?.url) return

    brazeLogInAppMessageClick(notificationInAppMessage)
  }

  const handleInAppMessageEnter = useCallback(() => {
    if (!anyInAppMessage) return

    brazeLogInAppMessageImpression(anyInAppMessage)
  }, [anyInAppMessage])

  if (notificationInAppMessage) {
    return (
      <NotificationInAppMessage
        onEnter={handleInAppMessageEnter}
        inAppMessage={notificationInAppMessage}
        onClose={handleNotificationClose}
        onLinkClick={handleNotificationLinkClick}
      />
    )
  }

  if (modalInAppMessage) {
    return (
      <ModalInAppMessage
        isOpen={isModalOpen}
        inAppMessage={modalInAppMessage}
        onClose={closeModal}
        onLinkClick={handleModalLinkClick}
        onEnter={handleInAppMessageEnter}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={handleSecondaryButtonClick}
      />
    )
  }

  return null
}

export default InAppMessage
