'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@vinted/web-ui'

import { getWalletBalance } from 'data/api'
import { getUserId } from 'state/session/selectors'
import { getLocale } from 'state/intl/selectors'
import { WalletBalanceModel } from 'types/models'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { transformWalletBalanceDto } from 'data/transformers/wallet'

const CurrentWalletAmount = () => {
  const locale = useSelector(getLocale)
  const currentUserId = useSelector(getUserId)

  const [balance, setBalance] = useState<WalletBalanceModel | null>()

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUserId) return

      const response = await getWalletBalance(currentUserId)

      if ('errors' in response) return

      setBalance(transformWalletBalanceDto(response.user_balance))
    }

    fetchData()
  }, [currentUserId])

  if (!balance) return null

  const { availableAmount } = balance

  return <Text type={Text.Type.Caption} text={formatCurrencyAmount(availableAmount, locale)} />
}

export default CurrentWalletAmount
