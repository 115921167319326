'use client'

import { useEffect, useRef } from 'react'
import { noop } from 'lodash'

import { VanPlacementConfigModel } from 'types/models'

import { getAdPlacementId } from '../utils'
import useRequestedAdLogging from '../useRequestedAdLogging'

type Props = {
  id: string
  config: VanPlacementConfigModel
  onAdRender?: (isAdVisible: boolean) => void
}

const VanAdvertisement = ({ id, config, onAdRender = noop }: Props) => {
  const hasBeenTrackedRef = useRef<boolean>(false)
  const { onRequest: onRequestedAdRequest } = useRequestedAdLogging(getAdPlacementId(config))

  useEffect(() => {
    onAdRender(true)
  }, [onAdRender])

  useEffect(() => {
    if (hasBeenTrackedRef.current) return

    onRequestedAdRequest()

    hasBeenTrackedRef.current = true
  }, [onRequestedAdRequest])

  return (
    <div data-testid="van-advertisement" id={id}>
      <iframe
        title={`van-${config.id}-${config.creative.id}-iframe`}
        src={config.creative.contentLink}
        width={config.creative.size.width}
        height={config.creative.size.height}
        style={{ border: 'none' }}
      />
    </div>
  )
}

export default VanAdvertisement
